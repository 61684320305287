<template>
  <div class="content">
    <div class="title-box">
      <div class="data-title">{{ title }}</div>
      <div class="top-item-box">
        <div class="top-box" v-for="(item,index) in tops" :key="index">
          <span class="top-index">TOP{{ index + 1 }}</span>
          <span class="top-title">{{ item.townName }}</span>
        </div>
      </div>
    </div>
    <div id="my-bar-line"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import {onMounted, reactive, ref, defineExpose} from 'vue'
import moment from 'moment'


export default {
  name: 'line-echarts',
  props: {
    title: {
      type: String,
      default: ''
    },
    echarts: {
      type: Object,
      default: () => {
        return {}
      }
    },
    topList: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const topColor = ref(['rgba(33, 82, 155, 0.2)', 'rgba(58, 110, 187, 0.2)', 'rgba(90, 141, 216, 0.2)', 'rgba(124, 170, 237, 0.2)', 'rgba(33, 82, 155, 0.2)', 'rgba(182, 209, 249, 0.2)'])
    const gridMonth = ref([])
    const tops = ref(props.topList)
    tops.value.map((item) => {
      item.townName = item.townName.length > 4 ? item.townName.substr(0, 4) + '...' : item.townName
    })

    function getDate() {
      const nowMonth = moment(new Date()).format("MM")
      let perItem = ''

      gridMonth.value.push(nowMonth + '月')
      for (let i = 1; i < 12; i++) {
        let per = parseInt(nowMonth) - i
        if (per > 0) {
          gridMonth.value.unshift((per > 9 ? per : '0' + per) + '月')
        } else {
          switch (per) {
            case 0:
              perItem = '12月'
              break
            case -1:
              perItem = '11月'
              break
            case -2:
              perItem = '10月'
              break
            case -3:
              perItem = '09月'
              break
            case -4:
              perItem = '08月'
              break
            case -5:
              perItem = '07月'
              break
            case -7:
              perItem = '06月'
              break
            case -8:
              perItem = '05月'
              break
            case -9:
              perItem = '04月'
              break
            case -10:
              perItem = '03月'
              break
            case -11:
              perItem = '02月'
              break
            default:
              break
          }
          gridMonth.value.unshift(perItem)
        }

      }
    }

    function initEcharts(topList) {
      let seriesData = []
      props.topList.map((item, index) => {
        item.seriesDatas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        item.mouthDatList.map((items, ind) => {
          gridMonth.value.map((ite, indexs) => {
            if (items.month == parseInt(ite)) {
              item.seriesDatas[indexs] = items.num
            }
          })
        })
        seriesData.push({
          name: item.townName,
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 5,
          showSymbol: false,
          lineStyle: {
            normal: {
              width: 1,
              color: '#B6D1F9'
            }
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: topColor.value[index]
              }, {
                offset: 0.8,
                color: topColor.value[index]
              }], false),
              shadowColor: topColor.value[index],
              shadowBlur: 10
            }
          },
          itemStyle: {
            normal: {
              color: '#B6D1F9',
              borderColor: topColor.value[index],
              borderWidth: 12

            }
          },
          data: item.seriesDatas
        })
      })
      echarts.init(document.getElementById('my-bar-line')).clear();
      echarts.init(document.getElementById('my-bar-line')).dispose();
      const myChart = echarts.init(
          document.getElementById('my-bar-line')
      )
      let option = {
        backgroundColor: '#fff',
        title: {
          textStyle: {
            fontWeight: 'normal',
            fontSize: 16,
            color: '#F1F1F3'
          },
          left: '6%'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#57617B'
            }
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false,
            lineStyle: {
              color: '#57617B'
            }
          },
          axisTick: {
            show: false
          },
          data: gridMonth.value
        }],
        yAxis: [{
          type: 'value',
          name: '万',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: '#57617B'
            }
          },
          axisLabel: {
            margin: 10,
            textStyle: {
              fontSize: 14
            }
          },
          splitLine: {
            lineStyle: {
              color: '#D0D0D0',
              type: 'dashed'
            }
          }
        }],
        series: seriesData
      }
      myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    }

    // watch(()=>props.topList,()=>{
    //   initEcharts()
    // },{deep:true})
    onMounted(() => {
      getDate()
      initEcharts()
    })
    // defineExpose({
    //   getDate,
    //   initEcharts
    // })
    return {
      tops,
      getDate,
      initEcharts
    }
  },
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;

  .title-box {
    height: .6rem;
    padding: 0 .09rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .data-title {
      margin-right: 1.67rem;
      font-weight: 400;
      font-size: .2rem;
      color: #333;
    }

    .top-item-box {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .top-box {
        display: flex;
        justify-content: space-around;
        font-size: .14rem;
        margin-left: .33rem;

        .top-index {
          font-weight: 700;
          color: #21529B;
        }

        .top-title {
          margin-left: .05rem;
          font-weight: 400;
          color: #999;
        }
      }

      & :nth-child(1) {
        margin-left: 0;
      }
    }


  }

  #my-bar-line {
    width: 100%;
    height: calc(100% - .6rem);
  }


}

</style>
