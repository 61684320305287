<template>
  <div class="card">
    <div class="df">
      <TitleType :title="'小镇活动'" :url="'/activity'"/>
      <Swiper :list="list"/>
    </div>
  </div>
</template>
<script>
import TitleType from '@/components/home/title-type'
import Swiper from '@/components/common/swiper'

export default {
  name: 'home-vr',
  components: {
    TitleType,
    Swiper
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    activityType: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    goPage(url) {
      this.$router.push(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  padding: 0.7rem;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .df {
    width: 12rem;
  }

}
</style>
