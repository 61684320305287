<template>
  <header class="header">
    <div class="header-banner">
      <swiper
          :allowTouchMove="false"
          :slides-per-view="1"
          :loop="true"
          @swiper="setSwiper"
          @slideChange="swiperChange"
          autoplay=false
          @mouseover="stopAutoPlay"
          @mouseout="mouseOut"
          :pagination="{
             clickable: true,
        }"
      >
        <swiper-slide
            @click="showVideo(item)"
            :data-swiper-autoplay="item.type === 'pic' ? 3000 : 0"
            v-for="(item, index) in banner"
            :key="index"
        >
          <div class="banner-item">
            <img
                class="item-img"
                v-if="item.type === 'pic'"
                :src="item.icon"
                alt=""
            />
            <video
                class="item-video"
                v-else
                :src="item.url"
                ref="video"
                muted
            />
          </div>
        </swiper-slide>
      </swiper>
      <div class="search-box">
        <Search :placeholder="'寻找小镇'" @searchClick="searchClick"/>
      </div>
    </div>
    <div class="header-dashboard">
      <div class="dashboard-content">
        <!--        <img-->
        <!--            class="content-border"-->
        <!--            v-for="(item, index) in 4"-->
        <!--            :key="index"-->
        <!--            src="/images/home/dashboard-border.png"-->
        <!--            alt=""-->
        <!--        />-->
        <div
            class="content-item"
            v-for="(item, index) in dashboard"
            :key="index"
        >
          <h3 class="item-value">{{ item.value }}</h3>
          <span class="item-title">{{ item.label }}</span>
        </div>
      </div>
    </div>
  </header>
  <Video
      @close="toggleVisible"
      :visible="visible"
      :src="active.video"
      :title="active.title"
      :desc="active.content"
      :poster="active.picture"
  />
</template>
<script>
import Video from '@/components/common/video/video'
import SwiperCore, {Autoplay, Pagination} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue'
import 'swiper/swiper.scss'
import Search from '@/components/common/searchbox'


SwiperCore.use([Autoplay, Pagination])
export default {
  name: '',
  components: {
    Swiper,
    SwiperSlide,
    Video,
    Search
  },
  props: {
    banner: {
      type: Array,
      default: () => []
    },
    dashboard: {
      type: Array,
      default: () => [
        {
          label: '建设行动试点镇',
          value: '0'
        },
        {
          label: '平台入驻小镇',
          value: '0'
        },
        {
          label: '重点建设项目',
          value: '0'
        },
        {
          label: '全省著名景点',
          value: '0'
        },
        {
          label: '全省小镇总量',
          value: '0'
        },
      ]
    }
  },
  data() {
    return {
      swiper: null,
      visible: false,
      active: {
        video: ''
      },
      video: null,

    }
  },

  methods: {
    showVideo(item) {
      if (item.type !== 'video') {
        return
      }
      this.active.video = item.url
      this.toggleVisible()
    },
    toggleVisible() {
      this.visible = !this.visible
      if (this.visible) {
        this.video.pause();
      } else {
        this.video.play()
      }
    },
    setSwiper(swiper) {
      this.swiper = swiper
    },
    stopAutoPlay() {
      this.swiper.autoplay.stop()
    },
    startAutoPlay() {
      this.swiper.autoplay.start()
    },
    searchClick(value) {
      this.$router.push(`/towns?kw=${value}`)
    },
    swiperChange(obj) {
      // console.log('------change',this.$refs.video,obj.realIndex,obj.activeIndex);
      if (this.banner[obj.realIndex].type === 'video') {
        this.stopAutoPlay()
        let trueIndexs = this.findTrueIndex(obj.realIndex)
        trueIndexs.forEach((trueIndex) => {
          this.video = this.$refs.video[trueIndex]
          this.video.play()
          this.video.addEventListener('ended', this.ended)
        })
      }
    },
    //获取播放器数组，swiper 开启loop 后会缓存数据
    findTrueIndex(index) {
      // let trueIndex = 0;
      let arrays = [];
      let videos = this.$refs.video;
      for (let i = 0; i < videos.length; i++) {
        if (this.banner[index].url == videos[i].src) {
          arrays.push(i);
        }
      }
      // console.log('----array',arrays);
      return arrays;
    },
    ended() {
      //重置视频时间
      let videos = this.$refs.video;
      for (let i = 0; i < videos.length; i++) {
        videos[i].currentTime = 0;
        videos[i].removeEventListener('ended', this.ended)
      }
      this.startAutoPlay()
    },
    mouseOut() {
      let m = this.swiper.realIndex;
      if (this.banner[m].type == 'pic') {
        this.startAutoPlay();
      }

    }
  },

  mounted() {

    setTimeout(() => {

      // console.log('---倒计时结束');
      // console.log('--banner--',this.banner, this.$refs.video)
      if (this.banner[this.banner.length > 1 ? 1 : 0].type == 'video') {
        this.video = this.$refs.video[0]
        this.video.play()
        this.video.addEventListener('ended', this.ended)
      } else {
        this.startAutoPlay();
      }

    }, 500)

  }
}
</script>
<style lang="scss" scoped>
.header {
  .header-banner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    .search-box {
      position: absolute;
      bottom: 1rem;
      z-index: 20;
      display: flex;
      justify-content: center;
      width: 100%;

    }


    .banner-item {
      display: block;
      width: 100%;
      height: 100%;

      .item-img,
      .item-video {
        display: block;
        width: 100%;
        height: 7.3rem;
        background: #000000;
        object-fit: cover;
      }
    }
  }

  .swiper_bgi {
    height: 2rem;
    background-image: url("/images/home/swiper_background.png");
    background-size: 100% 100%;
  }

  .header-dashboard {
    height: 2rem;
    background-image: url("/images/home/swiper_background.png");
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .dashboard-content {
      width: 12.84rem;
      height: 1.54rem;
      position: relative;
      //padding: 0.15rem 0.1rem;
      //background-image: url("/images/home/dashboard-content.png");
      //background-size: 100% 100%;
      display: flex;
      justify-content: space-around;
      box-sizing: border-box;

      .content-border {
        position: absolute;
        width: 0.15rem;
        height: 0.42rem;
        top: 50%;
        transform: translateY(-50%);

        &:nth-of-type(1) {
          left: 20%;
        }

        &:nth-of-type(2) {
          left: 40%;
        }

        &:nth-of-type(3) {
          left: 60%;
        }

        &:nth-of-type(4) {
          left: 80%;
        }
      }

      .content-item {
        text-align: center;
        padding-top: 0.06rem;

        .item-value {
          color: #fff;
          font-size: 0.64rem;
          display: block;
          line-height: 1.3;
          font-weight: normal;

          &::after {
            content: "个";
            font-size: 0.14rem;
            font-weight: normal;
          }
        }

        .item-title {
          color: #fff;
          display: block;
          font-size: 0.14rem;
          position: relative;

          &::after {
            position: absolute;
            right: -0.05rem;
            top: 0.02rem;
            content: " ";
            display: block;
            width: 0.04rem;
            height: 0.049rem;
            background-image: url("/images/home/quotes-down.png");
            background-size: 100% 100%;
          }

          &::before {
            position: absolute;
            left: -0.05rem;
            top: 0.02rem;
            content: " ";
            display: block;
            width: 0.04rem;
            height: 0.049rem;
            background-image: url("/images/home/quotes-up.png");
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>
