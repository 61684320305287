<template>
  <div class="card">
    <div class="df">
      <TitleType :title="'小镇故事'" :url="'/vr'"/>
      <div class="card-list">
        <div class="card-arrow">
          <img
              class="previous"
              src="/images/home/left.png"
              alt=""
          />
        </div>
        <swiper
            class="swiper"
            :controller="{ control: swiperControl }"
            :slides-per-view="5"
            :allowTouchMove="false"
            :navigation="{
          prevEl: '.previous',
          nextEl: '.next',
        }"
            @swiper="setSwiper"
        >
          <swiper-slide class="swiper-item" v-for="item in list" :key="item.id">
            <img
                v-if="item.id"
                @click="goPage(`/story?id=${item.id}`)"
                class="cover"
                :src="item.picture"
                alt=""
            />
            <!--            <img v-else class="cover" src="/images/home/story-empty.png" alt="">-->
            <Default v-else :width="1.8" :height="1.8" :imgWidth=".8" :imgHeight=".8" :borderRadius="50"/>
            <div class="addressName">{{ item.townName || '暂无' }}</div>
          </swiper-slide>
        </swiper>
        <div class="card-arrow">
          <img
              class="next"
              src="/images/home/right.png"
              alt=""
          />
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import SwiperCore, {Controller, Navigation} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue'
import 'swiper/swiper.scss'
import TitleType from '@/components/home/title-type'
import Default from '@/components/common/default'


SwiperCore.use([Controller, Navigation])
export default {
  name: 'home-vr',
  components: {
    Swiper,
    SwiperSlide,
    TitleType,
    Default
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      swiper: null,
      swiperControl: null
    }
  },
  methods: {
    goPage(url) {
      this.$router.push(url)
    },
    goUrl(link) {
      window.open(link, '_target')
    },
    setSwiper(swiper) {
      this.swiper = swiper
    },
    setSwiperControl(swiper) {
      this.swiperControl = swiper
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  //padding: 0.7rem;
  //height: 9.59rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .df {
    width: 12rem;
  }

  .line {
    position: absolute;
    left: 0;
    top: 6.85rem;
    width: 100%;
    height: .01rem;
    background: #424242;
  }

  .card-title {
    margin: 0 auto;
    width: 7.61rem;
    display: block;
  }

  .card-list {
    position: relative;
    width: 12rem;
    padding-top: .4rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .card-arrow {
      height: 1.8rem;
      line-height: 1.8rem;
      padding-top: .16rem;

      .previous {
        width: .39rem;
        height: .39rem;
        cursor: pointer;
        z-index: 9;
      }

      .next {
        width: 0.39rem;
        height: 0.39rem;
        cursor: pointer;
        z-index: 9;
      }

    }

    .swiper {
      flex: 1;
      margin: 0 .2rem;
      display: flex;
      justify-content: space-between;

      .swiper-item {
        height: 3.28rem;
        padding-top: .16rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .addressName {
        margin-top: .37rem;
        text-align: center;
        font-weight: 400;
        font-size: .22rem;
        color: #000
      }

      .cover {
        object-fit: cover;
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .control {
      display: block;
      width: 1.8rem;

      .title {
        margin-left: 0.73rem;
        margin-top: 0.5rem;
        text-align: center;
        line-height: 0.5rem;
        display: block;
        width: 0.36rem;
        font-size: 0.34rem;
        color: #000000;
      }

      .title-decoration {
        margin-top: 0.3rem;
        width: 0.36rem;
        display: block;
        text-align: center;
        margin-left: 0.73rem;
      }
    }
  }

  .card-tools {
    display: flex;
    justify-content: center;
    margin-top: 0.9rem;

    .tools-more {
      font-size: 0.22rem;
      color: #333333;
      cursor: pointer;

      &::after {
        margin-top: 0.12rem;
        display: block;
        content: "";
        width: 0.88rem;
        height: 0.02rem;
        background: #555555;
      }
    }
  }
}
</style>
