<template>
  <div class="content">
    <h3>{{ title }}</h3>
    <div class="item-box">
      <div class="item-item" v-for="(item,index) in list" :key="index">
        <span class="index" :style="{color: colors[index]}">NO.{{ index + 1 }}</span>
        <div class="progress">
          <a-progress :showInfo="false" :percent="item.num" :steps="30" :stroke-color="colors[index]"/>
          <span class="span">{{ item.name }}</span>
          <div class="dataSource">
            <span :style="{color: colors[index],'margin-right': '.05rem'}">{{ item.num }}</span>
            <span class="span">万</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "progress-echarts",
  props: {
    title: {
      type: String,
      default: ''
    },
    colors: {
      type: Array,
      default: () => {
        return []
      }
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  padding: .12rem 0 0 .16rem;
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 400;
    font-size: .2rem;
    color: #333;
    margin-bottom: .16rem;
  }

  .item-box {
    flex: 1;

    display: flex;
    flex-direction: column;

    .item-item {

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .index {
    font-weight: 700;
    font-size: .14rem;
    margin-top: -0.03rem;

  }

  .progress {
    display: flex;
    align-items: center;


    .span {
      font-weight: 400;
      font-size: .14rem;
      color: #999;

    }

    .dataSource {
      margin-left: .35rem;
      display: flex;
      align-items: center;
    }
  }

}

/deep/ .ant-progress-steps-item {
  width: .05rem !important;
  height: .15rem !important;
  border-radius: .18rem;
}

/deep/ .ant-progress-line {
  width: 2.69rem !important;
}

</style>
