<template>
  <div class="content">
    <h3 class="title">{{ title }}</h3>
    <div class="table">
      <div class="column" v-for="(item,index) in column" :key="index">
        <h5>{{ item.title }}</h5>
        <div class="item">
          <div class="list-item" v-for="(items,ind) in data" :key="ind">
            <div class="span" :style="{background: color[ind]}" v-if="index == 0"></div>
            <span :class="item.key != 'money' ? 'oStyle1' : 'oStyle2'"
                  :style="{color: item.key === 'money' ? color[ind] : ''}">{{
                item.key !== 'index' ? items[item.key] : 'TOP' + (ind + 1)
              }}</span>
            <span class="oStyle1" v-if="item.key === 'money'">万</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "table-echarts",
  props: {
    title: {
      type: String,
      default: ''
    },
    column: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    color: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;

  .title {
    padding: .12rem 0 0 .15rem;
    margin-bottom: .15rem;
    font-weight: 400;
    font-size: .2rem;
    color: #333;
  }

  .table {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding-left: .35rem;

    h5 {
      color: #999;
    }

    .column {
      .list-item {
        margin-bottom: .16rem;
        font-size: .14rem;
        display: flex;
        align-items: center;
        position: relative;

      }

      .oStyle1 {
        font-weight: 400;
        color: #999999;
      }

      oStyle2 {
        font-weight: 700;
        margin-right: .13rem;
      }

      .span {
        position: absolute;
        left: -.09rem;
        width: .073rem;
        height: .25rem;
        border-radius: .02rem;
      }

    }

  }
}

</style>