<template>
  <ul class="content-list">
    <li class="list-item" v-for="item in list" :key="item.id" @click="item.id && goPage(`/articledetail?id=${item.id}&pt=1${this.$route.query?.hasOwnProperty('tn')?`&tn=${this.$route.query?.tn}`:''}${this.$route.query?.hasOwnProperty('tid')?`&tid=${this.$route.query?.tid}`:''}`)">
      <div class="item-empty" v-if="!item.id">
        <img class="img" src="/images/home/tabs-empty.png" alt="">
      </div>
      <div class="content-text" v-else>
        <h3 class="item-title">{{ item.title }}</h3>
        <p class="item-content" v-html="item.content">
        </p>
        <div class="item-date">{{ $date.moment(item.createDate, 'YYYY-MM-DD') }}</div>
      </div>
    </li>
  </ul>
</template>

<script>

export default {
  name: "index",
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods:{
    goPage(url) {
      this.$router.push(url)
    },

  },
}
</script>

<style lang="scss" scoped>
.content-list {
  width: 4.5rem;
  //background-color: red;

  .list-item {
    position: relative;
    height: 1.08rem;
    margin-left: 0.15rem;
    margin-bottom: 0.14rem;
    box-sizing: border-box;
    border-bottom: 1px solid #c4c4c4;
    cursor: pointer;

    .content-text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .item-empty {
      display: flex;
      justify-content: center;
      align-items: center;

      .img {
        width: 1.9343rem;
        height: .9146rem;
      }
    }

    .item-title {
      font-size: 0.22rem;
      font-weight: normal;
      line-height: 0.29rem;
      color: #333333;
      @extend .text-oneLine;
    }

    .item-content {
      font-size: 0.16rem;
      line-height: 0.21rem;
      font-weight: normal;
      color: #999999;
      margin-top: 0.05rem;
      @include text-moreLine(3);
    }

    .item-date {
      position: absolute;
      bottom: -0.1rem;
      right: 0;
      line-height: 0.2rem;
      height: 0.2rem;
      text-align: right;
      font-size: 0.16rem;
      font-weight: normal;
      background: #ffffff;
      color: #666666;
    }
  }
}

</style>
