<template>
  <div class="content">
    <h3 class="cover-title">{{ title }}</h3>
    <div class="img">
      <img src="/images/home/persons.png" alt="">
    </div>
    <div class="cover-data">
      <span>{{ number }}</span>
      <span>（人）</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "number-echarts",
  props: {
    title: {
      type: String,
      default: ''
    },
    number: {
      type: Number,
      default: null
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;

  .cover-title {
    font-weight: 400;
    font-size: .2rem;
    padding: .11rem .15rem;
    box-sizing: border-box;
  }

  .cover-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .26rem 0 .13rem 0;

    & :nth-child(1) {
      font-weight: 700;
      font-size: .32rem;
      color: #22539C;
    }

    & :nth-child(2) {
      margin-left: .07rem;
      font-weight: 400;
      font-size: .14rem;
      color: #999;
    }
  }

  .img {
    display: flex;
    justify-content: center;

    img {
      width: 1.72rem;
      height: 1.85rem;
    }

  }
}

</style>