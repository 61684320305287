<template>
  <div class="card-list">
    <swiper
        class="swiper"
        :controller="{ control: swiperControl }"
        :slides-per-view="3"
        :allowTouchMove="false"
        :navigation="{
          prevEl: '.previous',
          nextEl: '.next',
        }"
        @swiper="setSwiper"

    >
      <img
          class="previous"
          src="/images/home/Vector118.png"
          alt=""
      />
      <swiper-slide class="swiper-item" v-for="(item,index) in list" :key="item.id"
      @click="item.id && goPage(`activitydetail?id=${item.id}`)"
      >
        <ActivityItem class="activity-item" :class="swiperIndex === index ? 'active' : ''" :data="item" :hideTag="true"/>
        <a-button class="addressName" :class="swiperIndex === index ? 'activeBtn' : ''" block="true" type="text"
                  @click="clickItem(index)">{{
            item.typeInfo || '暂无'
          }}
        </a-button>
      </swiper-slide>
      <img
          class="next"
          src="/images/home/Vector117.png"
          alt=""
      />
    </swiper>

  </div>
</template>

<script>
import SwiperCore, {Controller, Navigation} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue'
import 'swiper/swiper.scss'
import ActivityItem from '@/components/activity/activity-item'
import {reactive, ref} from "vue";


export default {
  name: "index",
  components: {
    Swiper,
    SwiperSlide,
    ActivityItem,
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      swiper: null,
      swiperControl: null,
      swiperIndex: 0
    }
  },
  methods: {
    // 点击按钮 ,设置对应点击下标
    clickItem(e) {
      this.swiperIndex = e
    },

    goPage(url) {

      this.$router.push(url)
    },


    goUrl(link) {
      this.window.open(link, '_target')
    },


    setSwiper(swiper) {
      this.swiper = swiper
    },


    setSwiperControl(swiper) {
      this.swiperControl = swiper
    },
  },
  // setup(props) {
  //   const list = reactive(props.list)
  //   const swiper = ref(null)
  //   const swiperControl = ref(null)
  //   // 当前点击选项下标
  //   const swiperIndex = ref(0)
  //
  //
  //   return {
  //     swiper,
  //     swiperControl,
  //     swiperIndex,
  //     clickItem
  //   }
  // }

}
</script>

<style lang="scss" scoped>
.active {
  //transition: all 300ms;
  //transform: scale(1.05);
  box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.card-list {
  width: 12rem;
  margin-top: .3rem;
  display: flex;
  justify-content: space-between;
  border-bottom: .01rem solid #D6D6D6;

  .swiper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .previous {
      position: absolute;
      bottom: .22rem;
      left: 0;
      width: .18rem;
      height: .16rem;
      cursor: pointer;
      z-index: 9;
    }

    .next {
      position: absolute;
      bottom: .22rem;
      right: 0;
      width: 0.18rem;
      height: 0.16rem;
      cursor: pointer;
      z-index: 9;
    }


    .swiper-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .activity-item {
      width: 3.94rem;
      height: 3.35rem;
      margin-bottom: .22rem;
    }

    .activeBtn {
      font-weight: bold;
    }

    .addressName {
      height: .6rem;
      text-align: center;
      font-weight: 400;
      font-size: .14rem;
      color: #333;

      &:hover {
        font-weight: bold;
        cursor: pointer;
      }
    }

  }
}

</style>
