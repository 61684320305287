<template>
  <div :class="size == 'normal' ? 'searchdiv-normal' : 'searchdiv-small'">
    <input
      class="input"
      maxlength="8"
      type="text"
      :placeholder="placeholder"
      @keyup.enter="search"
      v-model="name"
    />
    <img
      :class="size == 'normal' ? 'search-img-normal' : 'search-img-small'"
      src="/images/common/search-blue.png"
      @click="search"
    />
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: '',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    size: {
      // type: 'normal' || 'small',
      type: String,
      default: 'normal',
    },
  },
  emits: ['searchClick'],
  setup (props, ctx) {
    const name = ref(props.value)
    const search = () => {
      ctx.emit('searchClick', name.value)
    }

    return {
      name,
      search,
    }
  },
}
</script>

<style lang="scss" scoped>
.searchdiv {
  background: white;
  width: 8.67rem;

  border-radius: 0.4rem;
  display: flex;
  padding: 0.1rem 0.23rem;
  align-items: center;
}
.searchdiv-normal {
  @extend .searchdiv;
  height: 0.8rem;
}
.searchdiv-small {
   @extend .searchdiv;
  height: 0.5rem;
}

.input {
  border: 0;
  flex: 1;
  outline: none;
  &::placeholder {
    padding-left: 0.15rem;
    color: #666666;
  }
}
.search-img {
  //padding: 0.1rem;
  cursor: pointer;
}
.search-img-normal {
  @extend .search-img;
  width: 0.37rem;
  height: 0.36rem;
}
.search-img-small {
  @extend .search-img;
  width: 0.13rem + 0.1rem;
  height: 0.13rem + 0.1rem;
}
</style>
