<template>
  <div class="card">
    <div class="df">
      <TitleType :title="'小镇数据分析'" :url="'/vr'"/>
      <div class="echarts"
           v-if="topList.data.length || tableList.data.length || progressList.data.length || pieData.data.length || xnumber">
        <div class="first-row">
          <div class="person-data" v-if="topList.data.length">
            <LineEcharts :title="'小镇客流 TOP5'" ref="lineEchartsref" :topList="topList.data"/>
          </div>
          <div class="cover-num">
            <NumberEcharts :title="'覆盖总人数'" :number="xnumber"/>
          </div>
        </div>
        <div class="second-row">
          <div class="economic">
            <TableEcharts :column="columns" :title="'小镇经济分布TOP5'" :data="tableList.data" :color="color"/>
          </div>
          <div class="agricultural">
            <ProgressEcharts :title="'年度农经收益'" :colors="colors" :list="progressList.data"/>
          </div>
          <div class="industry">
            <PieEcharts :title="'小镇产业分类'" :pieData="pieData.data"/>
          </div>
        </div>
      </div>
      <Default v-else :width="12" :height="6.67" :imgWidth="1.13" :imgHeight="1.13" :prompt="'建设中...'"/>
    </div>
  </div>
</template>
<script>
// 板块标题
import TitleType from '@/components/home/title-type'
// 小镇客流组件
import LineEcharts from '@/components/echarts/line-echarts'
// 覆盖人数组件
import NumberEcharts from '@/components/echarts/number-echarts'
// 镇群经济数据组件
import TableEcharts from '@/components/echarts/table-echarts'
// 年度农经收益组件
import ProgressEcharts from '@/components/echarts/progress-echarts'
// 小镇产业分类组件
import PieEcharts from '@/components/echarts/pie-echarts'
// 缺省页组件
import Default from '@/components/common/default'
// vue组件
import {onMounted, reactive, ref, nextTick} from "vue";
// 数据请求api
import api from "@/api";


export default {
  name: 'index',
  components: {
    TitleType,
    LineEcharts,
    NumberEcharts,
    TableEcharts,
    ProgressEcharts,
    PieEcharts,
    Default
  },
  setup() {
    // 覆盖人数数据
    let xnumber = ref(null)
    // 小镇产业分类数据
    const pieData = reactive({data: []})
    // 小镇客流数据
    const topList = reactive({data: []})
    // 镇群经济数据 列表字段
    const columns = reactive([
      {
        title: "小镇",
        key: 'name'
      },
      {
        title: "排名",
        key: 'index'
      },
      {
        title: "占比",
        key: 'scale'
      },
      {
        title: "经济效益",
        key: 'money'
      },
    ])
    // 镇群经济数据
    let tableList = reactive({data: []})
    // 镇群经济数据 颜色
    const color = reactive(['#21529B', '#3A6EBB', '#5A8DD8', '#7CAAED', '#B6D1F9',])
    // 年度农经收益 颜色
    const colors = reactive(['#FFCD35', '#3A6EBB', '#56C2E2', '#7CAAED', '#B6D1F9',])
    // 年度农经收益数据
    const progressList = reactive({data: []})

    // 小镇客流数据请求
    const lineEchartsref = ref(null);

    function passengerData() {
      nextTick(async () => {
        const res = await api.passengerData()
        topList.data = res ? res : [];
        // lineEchartsref.value.getDate();
        // lineEchartsref.value.initEcharts(topList.data);
      })

    }

    // 覆盖人数数据请求
    async function coverData() {
      const res = await api.coverData()
      xnumber.value = res?.num ?? null
    }

    // 镇群经济数据请求
    async function townEconomic() {
      const res = await api.townEconomic()
      tableList.data = res ? res : []
      tableList.data.map((item) => {
        item.name = item.name.length > 6 ? item.name.substr(0, 6) + '...' : item.name
        item.scale = item.total ? parseFloat(((item.money / item.total) * 100)).toFixed(2) + '%' : '0%'
      })
    }

    // 年度农经收益数据请求
    async function progressEconomic() {
      const res = await api.progressEconomic()
      progressList.data = res ? res : []
    }

    // 小镇产业分类数据请求
    async function industryType() {
      const res = await api.industryType()
      pieData.data = res?.records ?? []
    }

    onMounted(() => {
      passengerData()
      coverData()
      townEconomic()
      progressEconomic()
      industryType()
    })


    return {
      xnumber,
      lineEchartsref,
      pieData,
      topList,
      columns,
      tableList,
      color,
      colors,
      progressList,
    }
  },
}
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 .09rem .23rem .03rem rgba(0, 0, 0, 0.06);
  border-radius: .05rem;

  .df {
    width: 12rem;


    .echarts {
      margin: .58rem 0 .3rem 0;

      width: 12rem;
      //height: 6.57rem;
      padding: .07rem 0 .14rem 0;
      box-shadow: 0rem .09rem .23rem .03rem rgba(0, 0, 0, .06);
      border-radius: .05rem;

      .first-row {
        display: flex;

        .person-data {
          flex: 1;
          height: 3.3rem;

        }

        .cover-num {
          width: 2.85rem;
          height: 3.3rem;

        }
      }

      .second-row {
        display: flex;

        .economic {
          width: 4rem;
          height: 3.05rem;
        }

        .agricultural {
          width: 5rem;
          height: 3.05rem;

        }

        .industry {
          flex: 1;
        }
      }

    }
  }


}
</style>
