<template>
  <div class="box" v-show="list.length>0">
    <swiper
        :spaceBetween="5"
        :loop="true"
        :autoplay="{
          delay: 3000,
        }"
        @swiper="setSwiper"
        @slideChange="swiperChange"
    >
      <swiper-slide v-for="(item, index) in list" :key="index"
        @click="swiperClick(item)"
      >
        <div class="control-item">
          <img :src="item.picture" class="control-img"   >
<!--          <video-->
<!--              class="item-video"-->
<!--              v-else-->
<!--              :src="item.url"-->
<!--              muted-->
<!--              autoplay-->
<!--              loop-->
<!--          />-->

        </div>

      </swiper-slide>

    </swiper>
    <div class="cover-box">

      <p class="desc">
       {{showText}}
      </p>
      <div style="display: flex">
        <div class="item-dot" v-for="(v,index) in list" :key="index"
        :style="index==currentIndex?{'background':'#E15C23'}:{'background':'#71778C'}">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/swiper.scss'
import {ref} from 'vue'
import {useRouter,useRoute} from 'vue-router'
export default {
  name: 'bannerswiper',
  components: {
    Swiper,
    SwiperSlide,
  },
  props:{
    list:{
      type: Array,
      default: ()=>[]
    },

  },
  emits:['swiperChange'],
  setup(props,ctx) {
    let swiper = null;
    let data = props.list || [];
    let currentIndex = ref(0);
    const showText = ref(data[0].title);


    const setSwiper = (s) => {
      swiper = s;
    }

    const onSwiper = (swiper) => {

    };
    const onSlideChange = (direction) => {
      if (direction == 'right'){
        swiper.slideTo(swiper.realIndex + 1,500,false);
      }else {
        swiper.slideTo(swiper.realIndex - 1,500,false);

      }

    };

    const router = useRouter();
    const route = useRoute();

    const swiperChange = (swiper) => {

      let m = swiper.realIndex;
      ctx.emit('swiperChange',m);
      currentIndex.value = m;
      showText.value = data[m].title;
    }

    const swiperClick = (s) =>{
      router.push(`/articledetail?id=${s.id}&pt=1${route.query.tn?`&tn=${route.query.tn}`:''}${route.query.tid?`&tid=${route.query.tid}`:''}`)
    }

    return {
      currentIndex,
      setSwiper,
      onSwiper,
      onSlideChange,
      swiperChange,
      showText,
      swiperClick
    };
  },
}
</script>

<style lang="scss" scoped>

.box {
  width: 6.6rem;
  display: flex;
  position: relative;
  height: 4.15rem;

}

.control-item {
  height: 3.15rem;
  width: 5rem;
  cursor: pointer;
}
.control-img,
.item-video
{
  width: 6.6rem;
  height: 4.15rem;
  object-fit: cover;
  display: block;
}
.cover-box {
  background: rgba(0,0,0,0.5);
  height: 0.64rem;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 0 0.2rem;
  justify-content: center;

  .desc {
    color: #ffffff;
    font-size: 0.22rem;
    margin-bottom: 0.05rem;
    @include text-moreLine(1)
  }
  .item-dot {
    width: 0.19rem;
    height: 0.03rem;
    //background: #E15C23;
    margin-right: 0.07rem;

  }


}


</style>
