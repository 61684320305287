<template>
  <div class="content">
    <h3>{{ title }}</h3>
    <div class="pieBox">
      <div id="pie-el"></div>
    </div>

  </div>
</template>

<script>
import * as echarts from 'echarts'
import {onMounted, reactive, ref, watch, watchEffect} from "vue";

export default {
  name: 'pie-echarts',
  props: {
    title: {
      type: String,
      default: ''
    },
    pieData: {
      type: Array,
      default: () => []
    },
  },
  setup(props) {
    const nameData = reactive({data: []})
    const pieData = reactive({data: []})

    function init() {
      props.pieData.map((item) => {
        nameData.data.push(item.name)
        pieData.data.push({
          name: item.name,
          value: parseFloat(item.percentage).toFixed(2)
        })
      })
      echarts.init(document.getElementById('pie-el')).clear();
      echarts.init(document.getElementById('pie-el')).dispose();
      const myChart = echarts.init(
          document.getElementById('pie-el')
      )
      let option = {
        'animation': true,
        'title': {
          'x': 'center',
          'textStyle': {
            'color': '#fff',
            'fontSize': 60,
            'fontWeight': 'normal',
            'align': 'center',
            'width': 'px'
          },
          'subtextStyle': {
            'color': '#fff',
            'fontSize': 20,
            'fontWeight': 'normal',
            'align': 'center'
          }
        },
        'legend': {
          'width': '100%',
          'left': 'center',
          'textStyle': {
            'color': '#666',
            'fontSize': 12
          },
          'icon': 'circle',
          'right': '0',
          'bottom': '0',
          'data': nameData.data
        },
        'series': [{
          'type': 'pie',
          'center': ['50%', '50%'],
          'radius': ['20%', '33%'],
          'color': ['#0053a0', '#2370c1', '#488fde', '#6eacf3'],
          'startAngle': 10,
          'labelLine': {
            'normal': {
              'length': 5
            }
          },
          'label': {
            'normal': {
              'formatter': '{b|{b}:}  {per|{d}%} ',
              'backgroundColor': 'rgba(255, 147, 38, 0)',
              'borderColor': 'transparent',
              'borderRadius': 4,
              'rich': {
                'a': {
                  'color': '#666',
                  'lineHeight': 12,
                  'align': 'center'
                },
                'hr': {
                  'borderColor': '#666',
                  "width": "100%",
                  'borderWidth': 1,
                  'height': 0
                },
                'b': {
                  'color': '#666',
                  'fontSize': 10,
                  'lineHeight': 12
                },
                'c': {
                  'fontSize': 10,
                  'color': '#666'
                },
                'per': {
                  'color': '#666',
                  'fontSize': 10,
                  'borderRadius': 2
                }
              },
              'textStyle': {
                'color': '#666',
                'fontSize': 10,
                'width': 296
              }
            }
          },

          'data': pieData.data
        }]
      }
      myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    }
    watch(props,function (newitem,olditem) {
      init()
    })

    onMounted(() => {
      init()
    })
    return {}

  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  padding: .12rem 0 0 .15rem;
  box-sizing: border-box;

  h3 {
    font-weight: 400;
    font-size: .2rem;
    color: #333;
  }

  .pieBox {
    width: 100%;
    height: 100%;

    #pie-el {
      width: 100%;
      height: 2.8rem;
      position: relative;

    }
  }


}

/deep/ canvas {
  position: absolute !important;
  left: 0px !important;
  top: -0.33rem !important;
}


</style>
