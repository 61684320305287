<template>
  <div :style="{width: width + 'rem',height: height + 'rem',borderRadius: borderRadius + '%'}" class="empty-box">
    <img :style="{width: imgWidth + 'rem',eight: imgHeight + 'rem'}" src="/images/home/empty-common.png" alt="">
    <p v-show="prompt">{{ prompt }}</p>
  </div>
</template>

<script>
export default {
  name: "empty-img",
  props: {
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    prompt: {
      type: String,
      default: ''
    },
    imgWidth: {
      type: Number,
      default: null
    },
    imgHeight: {
      type: Number,
      default: null
    },
    borderRadius: {
      type: Number,
      default: null
    },
  }
}
</script>

<style lang="scss" scoped>
.empty-box {
  background: #F1F1F1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-weight: 400;
    font-size: .18rem;
    color: #B8B8B8;

  }
}
</style>