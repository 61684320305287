<template>
  <div class="title-box">
    <div class="titleBox">
      <span class="left-title">{{ title }}</span>
      <div class="right-box" v-if="title !== '平台优势' && title!== '小镇数据分析' && title!== '小镇故事'">
        <span @click="goPage(url)"
              class="tools-more">查看更多</span>
        <img class="arrow-right" src="/images/home/arrow-right.png" alt="">
      </div>

    </div>
  </div>
</template>

<script>
import {reactive, toRefs} from "vue";

export default {
  name: "index",
  props: {
    title: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
  },
  methods:{
    goPage(url) {
      this.$router.push(url)
    },

  },
  setup(props) {
    const obj = reactive({
      title: props.title,
      url: props.url,
    })



    return {
      ...toRefs(obj),

    }

  }
}
</script>

<style lang="scss" scoped>
.title-box {
  &::before {
    content: "";
    display: block;
    width: .57rem;
    height: .03rem;
    background-color: #01398D;
    margin-bottom: .2rem;
  }

}

.card-title {
  width: 0.57rem;
  height: 0.03rem;
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-title {
    font-weight: 500;
    font-size: 30px;
    color: #000000;
  }

  .right-box {
    display: flex;
    align-items: center;

    .tools-more {
      margin-right: 0.05rem;
      font-weight: 400;
      font-size: .2rem;
      color: #666;
      cursor: pointer;
    }

    .arrow-right {
      width: 0.1rem;
      height: 0.18rem;
    }
  }
}
</style>
