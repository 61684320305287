<template>
  <div class="card">
    <div class="df">
      <TitleType :title="'精选小镇'" :url="'/besttowns'"/>
      <div class="card-content">
        <TownItem style="margin-right: 0.16rem" v-for="(item,index) in list" :key="index" :data="item"/>
      </div>
    </div>
  </div>
</template>
<script>
import TitleType from '@/components/home/title-type'
import TownItem from '@/components/common/townitem'

export default {
  name: 'home-town',
  components: {
    TitleType,
    TownItem
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // townList: [{
      //   id: 1,
      //   picture: 'https://t2.oss-chengdu1.yunzhicloud.com/town/image/2022/0523/efc52b952af94c9eb40c49a3ac01b4ed.jpg',
      //   tag: '美食',
      //   name: '黄龙溪古镇',
      //   city: '成都市',
      //   county: '双流区',
      //   introduction: '一段小镇简介50字以内一段小镇简介50字以内一段小镇简介50字以内',
      // }, {
      //   id: 2,
      //   picture: 'https://t2.oss-chengdu1.yunzhicloud.com/town/image/2022/0523/efc52b952af94c9eb40c49a3ac01b4ed.jpg',
      //   tag: '美食',
      //   name: '黄龙溪古镇',
      //   city: '成都市',
      //   county: '双流区',
      //   introduction: '一段小镇简介50字以内一段小镇简介50字以内一段小镇简介50字以内',
      // }, {
      //   id: 3,
      //   picture: 'https://t2.oss-chengdu1.yunzhicloud.com/town/image/2022/0523/efc52b952af94c9eb40c49a3ac01b4ed.jpg',
      //   tag: '美食',
      //   name: '黄龙溪古镇',
      //   city: '成都市',
      //   county: '双流区',
      //   introduction: '一段小镇简介50字以内一段小镇简介50字以内一段小镇简介50字以内',
      // }, {},]
    }
  },
  methods: {
    goPage(url) {
      this.$router.push(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  //padding: 0.7rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .df {
    width: 12rem;
  }

  .card-content {
    display: flex;
    //justify-content: center;
    margin-top: 0.4rem;

    .content-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 12rem;

      .list-item {
        width: 3.85rem;
        margin-bottom: 0.7rem;

        .item-value {
          position: relative;
          width: 100%;
          height: 5.17rem;
          cursor: pointer;
          background: #fff;

          .value-border-left {
            position: absolute;
            top: -0.05rem;
            left: -0.05rem;
            width: 3.9rem;
            height: 5.28rem;
          }

          .value-border-center {
            position: absolute;
            top: -0.03rem;
            left: -0.015rem;
            width: 3.87rem;
            height: 5.23rem;
          }

          .value-border-right {
            position: absolute;
            top: -0.05rem;
            left: -0.02rem;
            width: 3.95rem;
            height: 5.29rem;
          }

          .value-cover {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .item-title {
          margin-top: 0.36rem;
          color: #000000;
          font-size: 0.4rem;
          font-weight: normal;
          text-align: center;
        }
      }
    }
  }

  .card-tools {
    display: flex;
    justify-content: center;
    margin-top: 0.9rem;

    .tools-more {
      font-size: 0.22rem;
      color: #333333;
      cursor: pointer;

      &::after {
        margin-top: 0.12rem;
        display: block;
        content: "";
        width: 0.88rem;
        height: 0.02rem;
        background: #555555;
      }
    }
  }
}
</style>
