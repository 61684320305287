<template>
  <div class="card">
    <img class="card-title" src="/images/home/vr.png" alt="" />

    <div class="card-list">
      <img
        class="previous"
        src="@/assets/images/common/arrows-left-black.png"
        alt=""
      />
      <img
        class="next"
        src="@/assets/images/common/arrows-right-black.png"
        alt=""
      />
      <swiper
        :controller="{ control: swiperControl }"
        :slides-per-view="5"
        :allowTouchMove="false"
        :loop="true"
        :navigation="{
          prevEl: '.previous',
          nextEl: '.next',
        }"
        @swiper="setSwiper"
      >
        <swiper-slide v-for="item in list" :key="item.id">
          <img
            @click="goUrl(item.link)"
            class="cover"
            :src="item.picture"
            alt=""
          />
        </swiper-slide>
      </swiper>

      <swiper
        :slides-per-view="5"
        :allowTouchMove="false"
        :loop="true"
        :controller="{ control: swiper }"
        @swiper="setSwiperControl"
      >
        <swiper-slide v-for="item in list" :key="item.id">
          <div class="control">
            <span class="title">{{ item.townName.slice(0, 2) }}</span>
            <span class="title-decoration">|</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="card-tools">
      <span @click="goPage('/vr')" class="tools-more">了解更多</span>
    </div>
  </div>
</template>
<script>
import SwiperCore, { Controller, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
SwiperCore.use([Controller, Navigation])
export default {
  name: 'home-vr',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      swiper: null,
      swiperControl: null
    }
  },
  methods: {
    goPage (url) {
      this.$router.push(url)
    },
    goUrl (link) {
      window.open(link, '_target')
    },
    setSwiper (swiper) {
      this.swiper = swiper
    },
    setSwiperControl (swiper) {
      this.swiperControl = swiper
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  padding: 0.7rem;
  height: 9.59rem;
  box-sizing: border-box;
  background: url("/images/home/vr-bg.png") no-repeat;
  background-size: 100% 100%;
  .line {
    position: absolute;
    left: 0;
    top: 6.85rem;
    width: 100%;
    height: 0.01rem;
    background: #424242;
  }
  .card-title {
    margin: 0 auto;
    width: 7.61rem;
    display: block;
  }
  .card-list {
    position: re;
    width: 12rem;
    padding-top: 1.2rem;
    margin: 0 auto;
    .previous {
      width: 0.13rem;
      position: absolute;
      top: 5.5rem;
      cursor: pointer;
      z-index: 9;
    }
    .next {
      width: 0.13rem;
      top: 5.5rem;
      right: 4.2rem;
      position: absolute;
      cursor: pointer;
      z-index: 9;
    }
    .cover {
      object-fit: cover;
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
      cursor: pointer;
    }
    .control {
      display: block;
      width: 1.8rem;
      .title {
        margin-left: 0.73rem;
        margin-top: 0.5rem;
        text-align: center;
        line-height: 0.5rem;
        display: block;
        width: 0.36rem;
        font-size: 0.34rem;
        color: #000000;
      }
      .title-decoration {
        margin-top: 0.3rem;
        width: 0.36rem;
        display: block;
        text-align: center;
        margin-left: 0.73rem;
      }
    }
  }
  .card-tools {
    display: flex;
    justify-content: center;
    margin-top: 0.9rem;
    .tools-more {
      font-size: 0.22rem;
      color: #333333;
      cursor: pointer;
      &::after {
        margin-top: 0.12rem;
        display: block;
        content: "";
        width: 0.88rem;
        height: 0.02rem;
        background: #555555;
      }
    }
  }
}
</style>