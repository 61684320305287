<template>
  <div class="card">
    <!--    <img class="card-title" src="/images/home/advantage.png" alt="" />-->
    <div class="card-title">
      <TitleType :title="'平台优势'" :url="'/news'"/>
    </div>
    <div class="card-tools" @click="goPage('/apply')">
      <span class="tools-enter">马上入驻</span>
    </div>
    <div class="card-content">
      <ul class="content-list">
        <li class="list-item">
          <img class="item-cover" src="/images/home/advantage-1.png" alt=""/>
          <h3 class="item-title">官方平台</h3>
          <h4 class="item-desc">依托于新华网官方平台</h4>
        </li>
        <li class="list-item">
          <img class="item-cover" src="/images/home/advantage-2.png" alt=""/>
          <h3 class="item-title">全面展示</h3>
          <h4 class="item-desc">多形态展示小镇特点</h4>
        </li>
        <li class="list-item">
          <img class="item-cover" src="/images/home/advantage-3.png" alt=""/>
          <h3 class="item-title">专业解读</h3>
          <h4 class="item-desc">全方位、多角度诠释小镇特色</h4>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import TitleType from '@/components/home/title-type'

export default {
  name: 'home-advantage',
  components: {
    TitleType
  },
  methods: {
    goPage(url) {
      this.$router.push(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  padding: 0.7rem 0 1.7rem 0;
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;

  .card-title {
    width: 12rem;
  }


  .card-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0.4rem;

    .content-list {
      display: flex;
      flex-wrap: wrap;
      width: 12rem;
      justify-content: space-between;

      .list-item {
        width: 3.86rem;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          width: 3.86rem;
          height: 2.91rem;
          background: rgba(0, 0, 0, 0.22);
          z-index: 2;
        }

        .item-cover {
          height: 2.91rem;
          display: block;
        }

        .item-title {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          line-height: 2.91rem;
          text-align: center;
          font-size: 0.38rem;
          font-weight: normal;
          color: #fff;
          z-index: 3;
        }

        .item-desc {
          text-align: center;
          margin-top: 0.13rem;
          font-size: 0.22rem;
          font-weight: normal;
          color: #666666;
        }
      }
    }
  }

  .card-tools {
    width: 12rem;
    height: 1.37rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/images/home/MaskGroup.png');
    background-size: 100% 100%;
    margin-top: 0.31rem;

    .tools-enter {
      font-weight: 400;
      font-size: 0.22rem;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>