<template>
  <div class="card">
    <img class="card-title" src="/images/home/album.png" alt="" />
    <img class="card-bg-left" src="/images/home/album-left.png" alt="" />
    <img class="card-bg-right" src="/images/home/album-right.png" alt="" />
    <div id="certify">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="item in list"
            :key="item"
            class="swiper-slide"
            @click="goPage(`/albumdetail?id=${item.id}`)"
          >
            <img v-if="item.picture" :src="item.picture" />
          </div>
        </div>
      </div>
      <div class="swiper-button-prev base-btn">
        <img class="btn-img" src="/images/common/arrow-left-w.png" alt="" />
      </div>
      <div class="swiper-button-next base-btn">
        <img class="btn-img" src="/images/common/arrow-right-w.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/lib/swiper/swiper.min.css'
import Swiper from '@/assets/lib/swiper/swiper.min.js'
export default {
  name: 'home-album',
  components: {
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      swiper: null
    }
  },
  methods: {
    goPage (url) {
      this.$router.push(url)
    },
    initSwiper () {
      this.swiper = new Swiper('#certify .swiper-container', {
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        loopedSlides: 7,
        autoplay: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          progress: function () {
            for (let i = 0; i < this.slides.length; i++) {
              let slide = this.slides.eq(i)
              let slideProgress = this.slides[i].progress
              let modify = 1
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.1 + 1
              }
              let translate = slideProgress * modify * 4 + 'rem'
              let scale = 1 - Math.abs(slideProgress) / 5
              let zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
              slide.transform('translateX(' + translate + ') scale(' + scale + ')')
              slide.css('zIndex', zIndex)
              slide.css('opacity', 1)
              if (Math.abs(slideProgress) > 3) {
                slide.css('opacity', 0)
              }
            }
          },
          setTransition: function (transition) {
            for (let i = 0; i < this.slides.length; i++) {
              let slide = this.slides.eq(i)
              slide.transition(transition)
            }
          }
        }
      })
    }
  },
  mounted () {
    this.initSwiper()
  }
}
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  padding: 0.7rem;
  height: 9.45rem;
  background: #f4f4f4;
  box-sizing: border-box;
  .card-title {
    margin: 0 auto;
    width: 7.61rem;
    display: block;
  }
  .card-bg-left {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5rem;
  }
  .card-bg-right {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 5rem;
  }
  .cover {
    height: 5.45rem;
    width: 100%;
  }

  #certify {
    position: relative;
    top: 0.6rem;
    width: 12rem;
    margin: 0 auto;
  }

  #certify .swiper-slide {
    width: 6.69rem;
    height: 4.8rem;
    background: #fff;
    cursor: pointer;
  }
  #certify .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  .swiper-button-prev {
    width: 0.46rem;
    height: 0.46rem;
    background: rgba(0, 0, 0, 0.43);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      display: block;
      width: 0.13rem;
    }
  }
  .swiper-button-next {
    width: 0.46rem;
    height: 0.46rem;
    background: rgba(0, 0, 0, 0.43);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      display: block;
      width: 0.13rem;
    }
  }
}
</style>