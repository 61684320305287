<template>
  <div class="card">
    <div class="df">
      <TitleType :title="'小镇访谈录'" :url="'/interview'"/>
      <div class="card-content">
        <ul class="content-list">
          <li
              class="list-item"
              v-for="item in list"
              :key="item.id"
              @click="showDetail(item)"
          >
            <div class="item-content" v-if="item.id">
              <img class="item-cover" :src="item.picture" alt=""/>
              <h3 class="item-title">{{ item.title }}</h3>
              <p class="item-desc" v-html="item.content"></p>
            </div>
            <Default v-else :width="2.8321" :height="4.711" :imgWidth="1.13" :imgHeight="1.13" :prompt="'建设中...'"/>
          </li>
        </ul>
      </div>
    </div>

    <!--    <div class="card-tools">-->
    <!--      <span @click="goPage('/interview')" class="tools-more">了解更多</span>-->
    <!--    </div>-->
  </div>
  <Video
      @close="toggleVisible"
      :visible="visible"
      :src="active.video"
      :title="active.title"
      :desc="active.content"
      :poster="active.poster"
  />
</template>
<script>
import Video from '@/components/common/video/video'
import TitleType from '@/components/home/title-type'
import Default from '@/components/common/default'

export default {
  name: 'home-interview',
  components: {
    Video,
    TitleType,
    Default
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      active: {
        video: '',
        poster: '',
        title: '',
        content: ''
      }
    }
  },
  methods: {
    goPage(url) {
      this.$router.push(url)
    },
    toggleVisible() {
      this.visible = !this.visible
    },
    showDetail(data) {
      // this.active = {
      //   video: data.video,
      //   poster: data.picture,
      //   content: data.content,
      //   title: data.title
      // }
      // this.toggleVisible()
      this.$router.push(`/interviewdetail?id=${data.id}`)
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  padding: 0.7rem;
  background: #fff;
  //.card-title {
  //  margin: 0 auto;
  //  width: 7.61rem;
  //  display: block;
  //}
  display: flex;
  flex-direction: column;
  align-items: center;

  .df {
    width: 12rem;
  }

  .card-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 0.4rem;

    .content-list {
      display: flex;
      flex-wrap: wrap;
      width: 12rem;
      justify-content: space-between;

      .list-item {
        width: 2.83rem;
        height: 4.36rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        cursor: pointer;

        .item-empty {
          width: 2.83rem;
          height: 4.71rem;
        }

        .item-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
        }

        .item-cover {
          height: 3.21rem;
          width: 2.83rem;
          object-fit: cover;
          display: block;
        }

        .item-title {
          width: 2.83rem;
          margin-top: 0.26rem;
          font-size: 0.24rem;
          font-weight: normal;
          color: #000000;
          text-align: left;
          @extend .text-oneLine;
        }

        .item-desc {
          text-align: left;
          margin-top: 0.13rem;
          font-size: 0.16rem;
          color: #777777;
          flex-wrap: wrap;
          @include text-moreLine(3);
        }
      }
    }
  }

  .card-tools {
    display: flex;
    justify-content: center;
    margin-top: 0.9rem;

    .tools-more {
      font-size: 0.22rem;
      color: #333333;
      cursor: pointer;

      &::after {
        margin-top: 0.12rem;
        display: block;
        content: "";
        width: 0.88rem;
        height: 0.02rem;
        background: #555555;
      }
    }
  }
}
</style>
