<template>
  <div class="box-card">
    <div class="dataBox" v-if="data.id" @click="goPage(`/townsdetail?tid=${data.id}&tn=${data.name}`)">
      <img :src="data.picture" style="width: 100%;height:1.94rem">
      <div class="cover-box">
        <div class="left-tag"/>
        <p class="tag">{{ data.tag }}</p>
      </div>
      <div style="padding: 0 0.15rem">
        <div class="town-header-info">
          <span class="town-name">{{ data.name }}</span>
          <span class="town-area">{{ data.city }} · {{ data.county }}</span>
        </div>
        <div class="desc">
          {{ data.introduction }}
        </div>
      </div>
    </div>
    <Default v-else :width="2.88" :height="3.52" :imgWidth="1.13" :imgHeight="1.13" :prompt="'建设中...'"/>
  </div>

</template>

<script>
import Default from '@/components/common/default'

export default {
  name: 'TownItem',
  components: {
    Default
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  methods:{
    goPage(url) {
      this.$router.push(url)
    },

  },

}
</script>

<style lang="scss" scoped>
.box-card {

  .dataBox {
    width: 2.88rem;
    height: 3.52rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.2rem rgba(0, 0, 0, 0.12);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .town-empty {
    width: 2.88rem;
    height: 3.52rem;
  }

  .cover-box {
    width: 100%;
    height: 0.31rem;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    margin-top: calc(1.94rem - 0.31rem);
    display: flex;
    align-items: center;
    padding-left: 0.15rem;

    .left-tag {
      width: 0.02rem;
      height: 0.12rem;
      background: #FFDF36;
      margin-right: 0.04rem;
    }

    .tag {
      color: #FFFFFF;
      font-size: 0.12rem;
      @include text-moreLine(1)
    }
  }


  .town-header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.23rem;

    .town-name {
      font-size: 0.18rem;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      overflow: hidden;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      flex: 1;
    }

    .town-area {
      color: #999999;
      font-size: 0.12rem;
    }
  }

  .desc {
    color: #777777;
    margin-top: 0.19rem;
    font-size: 0.14rem;
    line-height: 0.18rem;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .cover {
    background: #FFCD35;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.12rem;
    margin-left: 0.15rem;


  }

}


</style>
