<template>
  <div class="box" v-if="data.id">
    <div class="top-box">
      <img :src="data.img" class="img">
      <div class="tag" :style="{'background':statusInfo.topColor}" v-if="!hideTag">
        {{ statusInfo.name }}
      </div>
    </div>
    <div class="bottom-box">
      <div class="bottom-top-box">
        <span class="title">
          {{ data.name }}
        </span>
        <div class="act-btn">
          参与活动
        </div>
      </div>
      <span class="desc">
        {{ data.description }}
      </span>
    </div>
    <div class="line"/>
    <div class="bottom-last-box">
      <span class="status" :style="{color: sInfo.color}">
        {{ sInfo.name }}
      </span>
      <span class="num">
<!--        已有122个作品参与-->
      </span>
    </div>
  </div>
  <Default v-else :width="3.93" :height="3.35" :imgWidth=".95" :imgHeight=".78" :prompt="'建设中...'"/>
</template>

<script>
import {reactive} from 'vue'
import Default from '@/components/common/default'

export default {
  name: 'ActivityItem',
  components: {
    Default
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    hideTag:{
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    let type = props.data.type || ''
    let status = props.data.state || ''
    var info = {
      name: '投票活动',
      topColor: '#FFA235',
      statusColor: '#D6D6D6'
    }
    switch (type) {
      case 'VOTE_ACTIVE':
        info = {
          name: '投票活动',
          topColor: '#FFA235',
          statusColor: '#D6D6D6'
        }
        break
      case 'VIEW_ACTIVE':
        info = {
          name: '展示活动',
          topColor: '#00CD9B',
          statusColor: '#D6D6D6'
        }
        break
      case 'SIGN_ACTIVE':
        info = {
          name: '报名活动',
          topColor: '#01398D',
          statusColor: '#D6D6D6'
        }
        break
      case 'DRAW_ACTIVE':
        info = {
          name: '抽奖活动',
          topColor: '#FF663F',
          statusColor: '#D6D6D6'
        }
        break
    }
    var sInfos = {}
    switch (status) {
      case 'DRAW_START': {
        sInfos = {
          name: '活动进行中',
          color: '#01BB6C'
        }
        break
      }
      case 'SIGN_START': {
        sInfos = {
          name: '火热报名中',
          color: '#FF663F'
        }
        break
      }
      case 'SIGN_NO': {
        sInfos = {
          name: '活动未开始',
          color: '#FF663F'
        }
        break
      }
      case 'SIGN_OVER': {
        sInfos = {
          name: '报名已结束',
          color: '#6D6D6'
        }
        break
      }
      case 'WORK_START': {
        sInfos = {
          name: '作品征集中',
          color: '#01BB6C'
        }
        break
      }
      case 'WORK_OVER': {
        sInfos = {
          name: '作品征集结束',
          color: '#6D6D6'
        }
        break
      }
      case 'VOTE_START': {
        sInfos = {
          name: '作品投票中',
          color: '#FFBF5E'
        }
        break
      }
    }


    const statusInfo = reactive(info)
    const sInfo = reactive(sInfos)

    return {
      statusInfo,
      sInfo
    }
  }
}
</script>

<style lang="scss" scoped>

.box {
  display: flex;
  flex-direction: column;
  width: 3.93rem;
  height: 3.35rem;
  border: 0.02rem solid #F2F2F2;
  border-radius: 0.03rem;
  overflow: hidden;
  cursor: pointer;


  .top-box {
    display: flex;
    position: relative;

    .img {
      width: 100%;
      height: 2rem;
      border-top-left-radius: 0.03rem;
      border-top-right-radius: 0.03rem;
    }

    .tag {

      width: 0.88rem;
      height: 0.26rem;
      font-size: 0.14rem;
      color: white;
      background: #01BB6C;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 0.03rem;
      border-bottom-right-radius: 0.03rem;
      //&::after {
      //  content: "";
      //  position: absolute;
      //  width: 0.1rem;
      //  height: 0.1rem;
      //  right: -0.09rem;
      //  top: 0;
      //  background: #000;
      //  transform: scaleX(-1);
      //  background:radial-gradient(circle at 0% 70%,transparent  0.1rem, #01BB6C 0.1rem);
      //}


    }
  }

  .bottom-box {
    margin: 0 0.14rem;

    .bottom-top-box {
      display: flex;
      justify-content: space-between;
      margin: 0.1rem 0;

      .title {
        flex: 1;
        font-size: 0.16rem;
        flex: 1;
        @include text-moreLine(1)

      }


    }

    .act-btn {
      width: 0.86rem;
      height: 0.26rem;
      border-radius: 0.13rem;
      border: 0.02rem solid #01398D;
      color: #01398D;
      font-size: 0.14rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .desc {
    font-size: 0.12rem;
    color: #999999;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 0.18rem;

  }

  .line {
    background: #F2F2F2;
    height: 0.01rem;
    margin: 0.11rem 0 0.08rem;

  }

  .bottom-last-box {
    display: flex;
    justify-content: space-between;
    margin: 0 0.14rem;
    flex: 1;
    align-items: center;

    .status {
      color: #01BB6C;
      font-size: 0.14rem;
    }

    .num {
      color: #AAAAAA;
      font-size: 0.14rem;
    }
  }

}


</style>
