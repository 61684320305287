<template>
  <div class="wrap">
    <!--    <img class="logo" src="/images/home/logo.png" alt="" />-->
    <Header :dashboard="dashboard" :banner="banner"/>
    <!--    小镇动态-->
    <News :newsList="news" :tabsData="tabsData"/>
    <!--    精选小镇-->
    <Town :list="towns"/>
    <Album v-if="cover.length" :list="cover"/>
    <Interview :list="interview"/>
    <!--    <Vr :list="vr"/>-->
    <Story :list="storyList"/>
    <DataAnalysis :list="dataAnalysis"/>
    <Activity :list="activity"/>
    <Industry :list="industry"/>
    <Advantage/>

  </div>
</template>
<script>
import {nextTick, reactive, ref} from 'vue'
import api from '@/api'
import Header from '@/components/home/header'
import News from '@/components/home/news'
import Town from '@/components/home/town'
import Album from '@/components/home/album'
import Interview from '@/components/home/interview'
import Vr from '@/components/home/vr'
import Story from '@/components/home/story'
import DataAnalysis from '@/components/home/data-analysis'
import Activity from '@/components/home/activity'
import Industry from '@/components/home/industry'
import Advantage from '@/components/home/advantage'

import {hideTextTag, formatDate} from '@/utils/tools'

export default {
  name: 'home',
  components: {
    Header,
    News,
    Town,
    Album,
    Interview,
    // Vr,
    Story,
    Activity,
    Industry,
    Advantage,
    DataAnalysis
  },
  setup() {
    // 各种数组长度
    let length = 0
    const dashboard = reactive(
        [
          {
            label: '建设行动试点镇',
            value: '0'
          },
          {
            label: '平台入驻小镇',
            value: '0'
          },
          {
            label: '重点建设项目',
            value: '0'
          },
          {
            label: '全省著名景点',
            value: '0'
          },
          {
            label: '全省小镇总量',
            value: '0'
          }
        ]
    )
    const banner = ref([])
    // 小镇动态轮播图数据
    const news = ref([])
    // 小镇动态右侧数据
    const tabsData = ref([
      {
        key: 'TOWN_DYNAMIC',
        tab: '小镇动态',
        datas: [],
      }, {
        key: 'POLICY_DOCUMENT',
        tab: '政策文件',
        datas: [],
      }, {
        key: 'INVESTMENT_INFORMATION',
        tab: '招商信息',
        datas: [],
      },
    ])
    // 精选小镇数据
    const towns = ref([])
    const cover = ref([])
    // 小镇访谈录数据
    const interview = ref([])
    // 小镇产业数据
    const industry = ref([])
    const vr = ref([])
    // 小镇数据分析数据
    const dataAnalysis = ref({})
    // 小镇活动数据
    const activity = ref([])
    // 小镇故事数据
    const storyList = ref([])

    const getData = () => {
      const getDashboard = async () => {
        let res = await api.getHomeDashboard()
        dashboard[0].value = res?.pilotTownNum ?? 0
        dashboard[1].value = res?.townNum ?? 0
        dashboard[2].value = res?.keyProjectsNum ?? 0
        dashboard[3].value = res?.scenicSpotNum ?? 0
        dashboard[4].value = res?.townTotalNum ?? 0
      }
      const getBanner = async () => {
        let res = await api.getHomeBanner()
        banner.value = res
      }
      // 小镇动态轮播图请求
      const getNews = async (value) => {
        let res = await api.hotspot()
        news.value = res || []
      }
      // 精选小镇数据请求
      const getBestTowns = async () => {
        let res = await api.bestTowns({
          pageNum: 1,
          pageSize: 4
        })
        towns.value = res?.records ?? []
        length = towns.value.length
        forItem(towns.value, 4, length)
      }
      const getTownCover = async () => {
        let res = await api.getTownCover({
          pageNum: 1,
          pageSize: 10
        })
        cover.value = res?.records ?? []
      }
      // 小镇访谈录请求
      const getInterview = async () => {
        const params = {
          pageNum: 1,
          pageSize: 4,
          tabs: 'TALK'
        }
        let res = await api.townStory(params)
        interview.value = res?.records ?? []
        length = interview.value.length
        forItem(interview.value, 4, length)
      }
      // 小镇活动请求
      const getActivity = async () => {
        const params = {
          pageNum: 1,
          pageSize: 9,
        }
        let res = await api.activityList(params)
        activity.value = res?.records ?? []
        activity.value.map((item) => {
          switch (item.type) {
            case 'VIEW_ACTIVE':
              item.typeInfo = '展示活动'
              break
            case 'SIGN_ACTIVE':
              item.typeInfo = '报名活动'
              break
            case 'VOTE_ACTIVE':
              item.typeInfo = '投票活动'
              break
            case 'DRAW_ACTIVE':
              item.typeInfo = '抽奖活动'
              break
            default:
              break
          }
        })
        length = activity.value.length
        forItem(activity.value, 3, length)
      }
      // 小镇产业请求
      const getIndustry = async () => {
        const params = {
          pageNum: 1,
          pageSize: 10,
          tabs: 'INDUSTRY'
        }
        let res = await api.townProduct(params)
        industry.value = res?.records ?? []
        length = industry.value.length
        forItem(industry.value, 4, length)
      }
      const getVr = async () => {
        let res = await api.getVrList({
          pageNum: 1,
          pageSize: 10
        })
        vr.value = res?.records ?? []
      }
      //小镇故事请求
      const getStory = async () => {
        const params = {
          pageNum: 1,
          pageSize: 10,
          tabs: 'STORY'
        }
        let res = await api.townStory(params)
        storyList.value = res?.records ?? []
        length = storyList.value.length
        forItem(storyList.value, 5, length)
      }

      getDashboard()
      getBanner()
      getNews()
      getBestTowns()
      // getTownCover()
      getInterview()
      getActivity()
      getIndustry()
      // getVr()
      getStory()
    }
    // 循环增加空对象
    const forItem = (field, total, length) => {
      for (let i = 0; i < total - length; i++) {
        field.push({})
      }
    }
    // 小镇动态右侧数据请求
    let params = {
      tabs: 'TREND',
      pageNum: 1,
      pageSize: 3
    }
    tabsData.value.map(async item => {
      params.townDynamicTypeEnum = item.key
      let res = await api.townProduct(params)
      item.datas = res?.records ?? []
      length = item.datas.length
      forItem(item.datas, 3, length)
    })


    getData()
    return {
      dashboard,
      banner,
      cover,
      news,
      tabsData,
      towns,
      interview,
      industry,
      vr,
      storyList,
      activity,
      dataAnalysis,
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;

  .logo {
    width: 4.53rem;
    position: fixed;
    z-index: 999;
    top: 0.13rem;
    left: 0.17rem;
    @media screen and (max-width: 1400px) {
      opacity: 0;
    }
  }
}
</style>
