<template>
  <div class="card">
    <div class="df">
      <TitleType :title="'小镇产业'" :url="'/industry'"/>
      <div class="card-content">
        <div class="content-right">
          <div v-for="item in list.slice(1, 5)" :key="item" class="right-item">
            <div v-if="item.id">
              <img class="item-cover" :src="item.picture" alt="" @click="goPage(`/articledetail?id=${item.id}`)"/>
              <h4 class="item-title">{{ item.title }}</h4>
            </div>
            <Default v-else :width="2.88" :height="2.88" :imgWidth=".95" :imgHeight=".78" :prompt="'建设中...'"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TitleType from '@/components/home/title-type'
import Default from '@/components/common/default'

export default {
  name: 'home-vr',
  components: {
    TitleType,
    Default
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    goPage(url) {
      this.$router.push(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  padding: 0.7rem;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .df {
    width: 12rem;
  }

  .card-content {
    margin-top: 0.4rem;
    display: flex;
    justify-content: space-between;

    .content-left {
      position: relative;
      width: 5.82rem;
      height: 5.27rem;
      border-bottom: 1px dashed #003c49;

      .left-cover {
        width: 100%;
        object-fit: cover;
        display: block;
        height: 3.72rem;
        cursor: pointer;
      }

      .left-decorate {
        position: absolute;
        width: 0.95rem;
        height: 1.08rem;
        bottom: -0.6rem;
        left: 0;
      }

      .left-title {
        margin-top: 0.35rem;
        font-weight: normal;
        font-size: 0.22rem;
      }
    }

    .content-right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .right-item {
        margin-bottom: 0.52rem;
        margin-right: .16rem;
        position: relative;

        &:nth-child(4) {
          margin-right: 0;
        }

        .item-cover {
          display: block;
          width: 2.88rem;
          height: 2.88rem;
          object-fit: cover;
          cursor: pointer;
          border-radius: .03rem;
        }

        .item-title {
          position: absolute;
          bottom: 0;
          //margin-top: 0.22rem;
          //width: 2.5rem;
          font-weight: 400;
          font-size: .16rem;
          color: #fff;
          margin: 0 0 .1rem .1rem;
          //line-height: 0.29rem;
          @extend .text-oneLine;

          //&::after {
          //  content: "";
          //  margin-top: 0.27rem;
          //  display: block;
          //  width: 0.577rem;
          //  height: 0.01rem;
          //  background: #003c49;
          //}
        }
      }
    }
  }

  .card-tools {
    display: flex;
    justify-content: center;
    margin-top: 0.9rem;

    .tools-more {
      font-size: 0.22rem;
      color: #333333;
      cursor: pointer;

      &::after {
        margin-top: 0.12rem;
        display: block;
        content: "";
        width: 0.88rem;
        height: 0.02rem;
        background: #555555;
      }
    }
  }
}
</style>