<template>
  <div class="card">
    <div class="df">
      <TitleType :title="'小镇动态'" :url="'/news'"/>
      <div class="card-content">
        <BannerSwiper v-if="newsList.length" :list="newsList"/>
        <img v-else class="content-banner" src="/images/home/swiper-empty.png" alt=""/>
        <div class="news-right-box">
          <a-tabs centered :tabBarStyle="tabBarStyle">
            <a-tab-pane v-for="item in tabsData" :key="item.key" :tab="item.tab">
              <TownList class="list" :list="item.datas"/>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BannerSwiper from '@/components/detail/towndetail/banner-swiper'
import TownList from '@/components/home/towns-list'
import TitleType from '@/components/home/title-type'
import {reactive, toRefs} from 'vue'

export default {
  name: 'home-news',
  components: {
    BannerSwiper,
    TownList,
    TitleType,
  },
  props: {
    newsList: {
      type: Array,
      default: () => []
    },
    tabsData: {
      type: Object,
      default: () => {
      }
    }
  },
  setup(props, content) {
    const data = reactive({
      tabBarStyle: {color: '#A8AEB6', width: '5.18rem'},
      newsList: props.newsList,
      tabsData: props.tabsData
    })

    function goPage(url) {
      this.$router.push(url)
    }

    return {
      ...toRefs(data),
      goPage,
    }
  },

}
</script>
<style lang="scss" scoped>
.card {
  padding: 0.7rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .df {
    width: 12rem;

    .card-content {
      width: 6.6rem;
      display: flex;
      justify-content: space-between;
      margin-top: 0.4rem;

      .content-banner {
        width: 6.6rem;
        height: 4.15rem;
      }

      .news-right-box {
        flex: 1;
        padding-left: .22rem;
        box-sizing: border-box;

        .list {
          width: 5.07rem;
        }

      }

    }
  }
}

/deep/ .ant-tabs-tab {
  width: calc(5.07rem / 3);
  padding: 0 0 !important;
  margin: 0 0 !important;
  font-size: .24rem !important;
  font-weight: 500 !important;
  margin-bottom: .12rem !important;

  &:hover {
    color: #01398D;
  }
}

/deep/ .ant-tabs-tab-active {
  color: #01398D;
}

/deep/ .ant-tabs-ink-bar {
  background: #01398D;
}

/deep/ .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #01398D;
}
</style>
